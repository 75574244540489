import React from 'react';

const MainLayout = ({ children }) => (
  <>
   
    <main>{children}</main>
   
  </>
);

export default MainLayout;