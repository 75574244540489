import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import address from '../assets/address.png';
import contact_kaju_2 from '../assets/contact_kaju_2.png';
import contact_kaju from '../assets/contact_kaju.png';
import loader from '../assets/loader.png';
import customisation_card_1 from '../assets/customisation_card_1.png';
import customisation_card_2 from '../assets/customisation_card_2.png';
import customisation_card_3 from '../assets/customisation_card_3.png';
import email from '../assets/email.png';
import facebook from '../assets/facebook.png';
import footerlogo from '../assets/footerlogo.png';
import foryorallhapymoments from '../assets/foryorallhapymoments.png';
import headerlogo from '../assets/headerlogo.png';
import hero_corousel_1 from '../assets/hero_corousel_1.png';
import order_now_card from '../assets/order_now_card.png';
import hero_corousel_2 from '../assets/hero_corousel_2.png';
import instagram from '../assets/instagram.png';
import kaju_bottom_fix from '../assets/kaju_bottom_fix.png';
import kaju_top_fix from '../assets/kaju_top_fix.png';
import phone_flag from '../assets/phone_flag.png';
import phone from '../assets/phone.png';
import quote from '../assets/quote.png';
import twitter from '../assets/twitter.png';
import what_our_client_say_bg from '../assets/what_our_client_say_bg.png';
import what_we_do_left from '../assets/what_we_do_left.png';
import what_we_do_mid from '../assets/what_we_do_mid2.png';
import what_we_do_right from '../assets/what_we_do_right.png';
import youtube from '../assets/youtube.png';

const Container = styled.div`
  font-family: 'Montserrat', 'Great Vibes', 'Cormorant', sans-serif;
  color: #000000;
  max-width: 100%;
  margin: 0 auto;
  background-color: #FDF3E7;
  overflow-x: hidden;
`;

const HeroCarousel = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const HeroImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
`;

const OrderNowCard = styled(motion.div)`
  background-image: url(${order_now_card});
  background-size: cover;
  background-position: center;
  padding: 18rem 2rem;
  text-align: center;
  @media (max-width: 768px) {
    background-size: contain;
    background-repeat: no-repeat;
    padding: 4rem 1rem;
  }
`;
const HoverOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  padding: 1rem;
  text-align: center;
  color: white;
  overflow-y: auto;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;
const OurStorySection = styled.section`
  position: relative;
  padding: 4rem 0;
  background-color: #FFFFFF;
  overflow: hidden;
`;

const StoryContent = styled(motion.div)`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  position: relative;
  z-index: 10;
`;

const SectionTitle = styled.h1`
  font-family: 'Great Vibes';
  color: #C19D56;
  font-weight: 400;
  font-size: 45px;
  text-align: center;
  margin-bottom: -1.5rem;
`;

const SectionTitle2 = styled.h1`
  font-family: 'Great Vibes';
  color: #C19D56;
  font-weight: 400;
  font-size: 45px;
  text-align: center;
  margin-bottom: 0.5rem;
`;

const SectionTitle3 = styled.h1`
  font-family: 'Montserrat';
  color: #000000;
  font-weight: 600;
  font-size: 36px;
  margin-bottom: 1.5rem;  
  @media (max-width: 768px) {
  font-size: 24px;
    
  }
`;

const SectionSubtitle = styled.h1`
  font-family: 'Montserrat', sans-serif;
  color: #000000;
  display: flex;
  justify-content: center;
  font-size: 2rem;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
        font-size: 1.5rem;
    margin-bottom: 1rem;
    margin-top: 42px;
  }
`;

const SectionText = styled.p`
  font-family: 'Cormorant', serif;
  font-style: italic;
  color: #333333;
  font-size: 1.4rem;
  max-width: 800px;
  margin: 0 auto 2rem;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 100px;
  margin-right: 100px;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;
  }
`;
const Card = styled(motion.div)`
  width: 30%;
  margin: 0 15px;
  transition: transform 0.3s ease;
  position: relative;
  overflow: hidden;

 
  @media (max-width: 768px) {
    width: 90%;
    margin: 15px auto;
  }
`;

const SmallCard = styled(Card)`
  width: 25%; // Adjust this percentage as needed
  position: relative;
  cursor: pointer;
  overflow: hidden;
    font-size: 0.8rem;
  
  &:hover ${HoverOverlay} {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.7);
     @media (max-width: 768px) {
    font-size: 0.7rem;
  }
  }
  
  @media (max-width: 768px) {
    width: 90%;
  }
`;

const LargeCard = styled(Card)`
  width: 35%;
  position: relative;
  cursor: pointer;
    font-size: 0.8rem;
  
  &:hover ${HoverOverlay} {
    opacity: 1;
    font-size: 0.7rem;

    background-color: rgba(0, 0, 0, 0.7);

  }
  
  @media (max-width: 768px) {
    width: 90%;
  }
`;

const OverlayTitle = styled.h3`
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const OverlayText = styled.p`
  font-family: 'Cormorant', serif;
  font-size: 1rem;
  margin-bottom: 1rem;
`;

const OverlayButton = styled.button`
  background-color: #C19D56;
  color: #fff;
  border: none;
  padding: 0.75rem 1.5rem;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #A67D3D;
  }
`;

const CardImage = styled(motion.img)`
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
`;

const CardTitle = styled.h4`
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
`;

const CardText = styled.p`
  font-family: 'Cormorant', serif;
  font-size: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;

 
`;

const HappyMomentsSection = styled.section`
  background-color: #FDF3E7;
  padding: 2rem 0;
  text-align: center;

   @media (max-width: 768px) {
  padding: 0rem 0;

  }
`;

const HappyMomentsImage = styled(motion.img)`
  width: 85%;
  margin-left: 300px;
  margin-right: 300px;
  max-width: 1300px !important;
  height: auto;
  margin: 0 auto;

  @media (max-width: 1024px) {
    margin-left: 50px;
    margin-right: 50px;
  }

  @media (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const TestimonialsSection = styled.section`
  background-image: url(${what_our_client_say_bg});
  background-size: 240px;
  background-position: top right;
  background-repeat: no-repeat;
  padding: 2rem 0;
  position: relative;
  background-color: #FDF3E7;

  @media (max-width: 768px) {
    background-size: 120px;
    margin: 0 20px;
  }
`;
const TestimonialContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  overflow-x: hidden;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const TestimonialCard = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.9);
  padding: 2rem;
  border-radius: 10px;
  width: 30%;
  margin: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const QuoteIcon = styled.img`
  width: 30px;
  margin-bottom: 1rem;
`;

const TestimonialText = styled.p`
  font-family: 'Cormorant', serif;
  font-size: 1.1rem;
  margin-bottom: 1rem;
`;

const TestimonialAuthor = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-size: 14px !important;
  font-weight: bold;
`;

const ContactSection = styled.section`
  position: relative;
  padding: 3rem 0;
  background-color: #FDF3E7;
  overflow: hidden;
  @media (max-width: 768px) {
    margin: 0 20px;
  }
`;

const KajuTopImage = styled.img`
  position: absolute;
  top: -60px;
  right: 0;
  width: 350px;
  z-index: 5;

  @media (max-width: 768px) {
    width: 150px;
  }
`;

const KajuBottomImage = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 350px;
  z-index: 5;

  @media (max-width: 768px) {
    width: 160px;
  }
`;

const ContactForm = styled(motion.form)`
  max-width: 600px;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 10;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const FormRow = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FormInput = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
`;

const FormTextarea = styled.textarea`
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  resize: vertical;
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
`;
const PhoneInput = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
`;

const PhoneFlag = styled.img`
  width: 24px;
  height: 24px;
`;

const FormButton = styled(motion.button)`
  width: 100%;
  background-color: #C19D56;
  color: #fff;
  border: none;
  padding: 0.75rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #A67D3D;
  }
`;

const Footer = styled.footer`
  background-color: #000000;
  color: #FFFFFF;
  padding: 2rem 5%;
  padding-bottom: 1px;
`;


const FooterInfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.6rem;
`;

const FooterIcon = styled.img`
  width: 20px;
  height: 20px;
  
`;

const FooterText = styled.p`
  font-size: 0.9rem;
  margin: 0;
  
`;

const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const FooterLink = styled.a`
  text-decoration: none;
  color: #FFFFFF;
  font-size: 0.9rem;
  margin-bottom: 0.6rem;

  transition: color 0.3s ease;
  &:hover {
    color: #C19D56;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 1rem;
`;

const SocialIcon = styled(motion.img)`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const Copyright = styled.p`
  font-size: 0.8rem;
  color: #AAA6A6;
  text-align: center;
  margin-top: 2rem;
`;

const ScrollToTopButton = styled(motion.button)`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #C19D56;
  color: #FFFFFF;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #A67D3D;
  }
`;

const loadingAnimation = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
`;

const LoaderOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: #FDF3E7;
`;

const LoaderText = styled.div`
  font-family: 'Great Vibes', cursive;
  font-size: 2rem;
  color: #C19D56;
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background-color: #FDF3E7;
    animation: ${loadingAnimation} 2s linear infinite;
  }
`;
  const Header = styled.header`
  background-color: #FDF3E7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`;

const Logo = styled.img`
  height: 50px;
`;


const Nav = styled.nav`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavItem = styled.a`
  color: #000000;
  text-decoration: none;
  margin: 0 1rem;
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase;
  transition: color 0.3s ease;

  &:hover {
    color: #C19D56;
  }
`;

const HeroSection = styled.section`
  position: relative;
  height: calc(100vh - 80px);
  margin-top: 80px;
  background-color: #FDF3E7;
  overflow: hidden;
`;

const HeroContent = styled.div`
  position: absolute;
  top: 50%;
  left: 21%;
  transform: translate(-50%, -50%);
  z-index: 10;
  text-align: center;
  width: 100%;

  @media (max-width: 768px) {
    left: 50%;
  }
`;

const SaffronTitle = styled.h2`
  font-family: 'Great Vibes', cursive;
  color: #C19D56;
  font-size: 60px;
  font-weight: 400;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    font-size: 40px;
  }
`;

const AuthenticTitle = styled.h1`
  font-family: 'Montserrat', sans-serif;
  font-size: 95px;
  font-weight: 600;
  margin: 0px !important;
  margin-bottom: 1rem;
  color: #000;

  @media (max-width: 768px) {
    font-size: 60px;
  }
`;

const HeroSubtitle = styled.div`
  font-family: 'Cormorant', serif;
  font-size: 26px;
  font-style: italic;
  margin-bottom: 1.5rem;
  color: #000;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const SubtitleLine = styled.p`
  margin: 0;
`;

const WhatWeDoSection = styled.section`
  background-color: #FDF3E7;
  padding: 0rem 0;
  text-align: center;
`;

const ContactButton = styled.button`
  background-color: #C19D56;
  color: #fff;
  border: none;
  padding: 0.75rem 1.5rem;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 1rem;

  &:hover {
    background-color: #A67D3D;
  }
`;

const RunningQuote = styled.div`
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  overflow: hidden;
  padding: 10px 0;
`;

const RunningQuoteText = styled.p`
  color: #C19D56;
  font-size: 18px;
  white-space: nowrap;
  animation: runningText 30s linear infinite;

  @keyframes runningText {
    0% { transform: translateX(100%); }
    100% { transform: translateX(-100%); }
  }
`;

const CustomizationSection = styled.section`
  background-color: #FFF;
  padding: 2rem 0;
  text-align: center;
`;

const CustomizationCards = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const CustomizationCard = styled.div`
  width: 30%;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const CustomizationImage = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
`;

const CustomizationTitle = styled.h4`
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`;

const CustomizationText = styled.p`
  font-family: 'Cormorant', serif;
  font-size: 1rem;
`;

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1000;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const ModalContent = styled.div`
  text-align: center;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;

const SuccessModal = ({ onClose }) => (
  <>
    <Overlay onClick={onClose} />
    <Modal>
      <CloseButton onClick={onClose}>×</CloseButton>
      <ModalContent>
        <h2>Thank You!</h2>
        <p>Your details have been submitted successfully. We will contact you soon.</p>
      </ModalContent>
    </Modal>
  </>
);



const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    width: 100%;
    align-items: flex-start;

    &:first-child, &:last-child {
      align-items: center;
    }
  }
`;

const FooterColumn2 = styled(FooterColumn)`
  @media (max-width: 768px) {
    align-items: center;
  }
`;
const HamburgerMenu = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;

  @media (max-width: 768px) {
    display: flex;
  }

  span {
    height: 2px;
    width: 25px;
    background: #000000;
    margin-bottom: 4px;
    border-radius: 5px;
  }
`;

const MobileNav = styled.nav`
  display: none;
  
  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    background: #FDF3E7;
    padding: 2rem;
    z-index: 1000;
    overflow-y: auto;
  }
`;

const MobileNavItem = styled(NavItem)`
  margin: 1rem 0;
  font-size: 1.2rem;
  text-align: center;
`;
const FooterLogo = styled.img`
  width: 170px;
  display: block;
  margin: 0 auto;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const SinceText = styled.span`
  font-size: 0.8rem;
  margin-left: 5rem;
`;

const FooterContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 2rem;
  align-items: stretch;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FooterInfoColumn = styled(FooterColumn)`
  justify-content: center;
   @media (max-width: 768px) {
    width: 100%;
    align-items: center;
  }
`;

const FooterInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
`;


const CallUsButton = styled.button`
  background-color: #C19D56;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #A67D3D;
  }
`;

const CTALink = styled.a`
  color: #FFFFFF;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: #C19D56;
  }
`;

const HomePage = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const cards = [
    { image: what_we_do_left, title: "Catering Service", text: "Exquisite culinary experiences for all occasions." },
    { image: what_we_do_mid, title: "Kaju Katli Boxes", text: "Premium sweet boxes crafted with love and tradition." },
    { image: what_we_do_right, title: "Event Planning", text: "Comprehensive event management for memorable occasions." }
  ];

  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const [currentSlide, setCurrentSlide] = useState(0);
  const images = [hero_corousel_1, hero_corousel_2];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev === 0 ? 1 : 0));
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollToTop(window.pageYOffset > 300);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    if (e.target[0].value.length > 100) {
      alert("Name should be 100 characters or less");
      return;
    }

    if (e.target[2].value.length > 500) {
      alert("Message should be 500 characters or less");
      return;
    }

    const phoneRegex = /^[6-9]\d{9}$/;
    if (!phoneRegex.test(e.target[1].value)) {
      alert("Please enter a valid 10-digit Indian mobile number");
      return;
    }
    const formData = {
      fullName: e.target[0].value,
      phone: e.target[1].value,
      message: e.target[2].value,
    };
  
    try {
      const response = await fetch('https://script.google.com/macros/s/AKfycbxhzu88OyMbcRI2Zvq-_HvTBsw7HqQk2oxhzt_Opr_RIjbDOHCsKKT-ycfNKAS0xBrvkA/exec', {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      console.log('Form submitted successfully!');
      
      e.target.reset();
      
      setShowModal(true);
    } catch (error) {
      console.error('Error submitting form', error);
    } finally {
      setIsLoading(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const closeMenu = () => setIsMenuOpen(false);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMenuOpen && !event.target.closest('header')) {
        setIsMenuOpen(false);
      }
    };
  
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isMenuOpen]);
  return (
    <Container>
      {showModal && <SuccessModal onClose={() => setShowModal(false)} />}

      {isLoading && (
        <LoaderOverlay>
          <LoaderText>
          <img src={loader} alt="Loader" />
          </LoaderText>
        </LoaderOverlay>
      )}
        <Header>
        <LogoContainer>
          <a href="https://saffroncaterers.com">
            <Logo src={headerlogo} alt="Saffron Caterers Logo" />
          </a>
          <SinceText>SINCE 1980</SinceText>
        </LogoContainer>
        <Nav>
          <NavItem href="#story">OUR STORY</NavItem>
          <NavItem href="#what-we-do">WHAT WE DO</NavItem>
          <NavItem href="#occasions">FOR OCCASIONS</NavItem>
          <NavItem href="#testimonials">TESTIMONIALS</NavItem>
          <NavItem href="#"></NavItem>
          <NavItem href="#"></NavItem>
          <NavItem href="#"></NavItem>
          <CallUsButton onClick={() => scrollToSection('contact')}>
            CALL US
          </CallUsButton>
        </Nav>
      <HamburgerMenu onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </HamburgerMenu>
      <MobileNav isOpen={isMenuOpen}>
  <MobileNavItem href="#story" onClick={closeMenu}>OUR STORY</MobileNavItem>
  <MobileNavItem href="#what-we-do" onClick={closeMenu}>WHAT WE DO</MobileNavItem>
  <MobileNavItem href="#occasions" onClick={closeMenu}>FOR OCCASIONS</MobileNavItem>
  <MobileNavItem href="#testimonials" onClick={closeMenu}>TESTIMONIALS</MobileNavItem>
  <MobileNavItem onClick={() => { scrollToSection('contact'); closeMenu(); }}>
    CALL US
  </MobileNavItem>
</MobileNav>
    </Header>

      <HeroSection>
        <HeroCarousel>
          {images.map((img, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0 }}
              animate={{ opacity: currentSlide === index ? 1 : 0 }}
              transition={{ duration: 1 }}
              style={{ position: 'absolute', width: '100%', height: '100%' }}
            >
              <HeroImage src={img} alt={`Hero ${index + 1}`} />
            </motion.div>
          ))}
        </HeroCarousel>
        <HeroContent>
          <SaffronTitle>Saffron Caterers</SaffronTitle>
          <AuthenticTitle>AUTHENTIC</AuthenticTitle>
          <img src={loader} alt="Loader" />
          <HeroSubtitle>
            <SubtitleLine>Exquisite Indoor & Outdoor Catering Services,</SubtitleLine>
            <SubtitleLine>Specializing in Premium Kaju Katli Boxes</SubtitleLine>
          </HeroSubtitle>
          <ContactButton onClick={() => scrollToSection('contact')}>
            CONTACT US
          </ContactButton>
        </HeroContent>
        <RunningQuote>
          <RunningQuoteText>
            "Savor the moment, taste the excellence - Saffron Caterers, where every bite tells a story."
          </RunningQuoteText>
        </RunningQuote>
      </HeroSection>

      <OurStorySection id="story">
        <KajuTopImage src={kaju_bottom_fix} alt="Kaju Top" />
        <StoryContent>
          <SectionTitle>inspiring</SectionTitle>
          <SectionSubtitle>OUR STORY</SectionSubtitle>
          <SectionText>
            Our Executive Chef and Sales Manager have worked tirelessly to build up our reputation and customer base since our beginnings as a small company in 2008, utilizing their many years in the catering and hospitality scene. We are devoted to ensuring the highest possible standards for our clients through a combination of innovation and creativity, and have expanded team to include three new banquet chefs, an Operations Manager, three Event Managers, and a team of service employees. We Come up with innovative concepts, pay special attention to detail, and collaborate closely with each client to give each event it's own identity.
          </SectionText>
          <SectionText>
            Our mission at Saffron is to create memorable dining experiences that bring people together, celebrate special moments, and leave a lasting impression. We believe that food is more than just sustenance—it's an experience, a connection, and a celebration of life's joys.
          </SectionText>
        </StoryContent>
        <KajuBottomImage src={kaju_top_fix} alt="Kaju Bottom" />
      </OurStorySection>
      <OrderNowCard
        initial="hidden"
        animate={controls}
        onClick={() => scrollToSection('contact')} style={{cursor: "pointer"}}
      >
      </OrderNowCard>
      <WhatWeDoSection id="what-we-do">
        <SectionTitle>Let's tell you</SectionTitle>
        <SectionSubtitle>WHAT WE DO</SectionSubtitle>
        <img src={loader} alt="Loader" />
        <SectionText>
          At Saffron, we are passionate about crafting exquisite Kaju Katli sweet boxes that are a celebration of taste and
          luxury. Each box is meticulously prepared using only the finest ingredients, ensuring a truly unforgettable experience.
        </SectionText>
        <CardContainer>
        <SmallCard onClick={() => scrollToSection('contact')}>
    <CardImage src={what_we_do_left} alt="Catering Service" />
    <CardTitle>Catering Service</CardTitle>
    <HoverOverlay>
      <OverlayTitle>Indoor Catering</OverlayTitle>
      <OverlayText>
      Elevate your special occasions with our premium indoor catering services. From intimate family gatherings to grand corporate events, Saffron crafts a personalized culinary experience within the comfort of your chosen venue.
      </OverlayText>
      <OverlayButton onClick={(e) => {
        e.stopPropagation();
        scrollToSection('contact');
      }}>
        Contact us
      </OverlayButton>
    </HoverOverlay>
  </SmallCard>
  <LargeCard onClick={() => scrollToSection('contact')}>
  <CardImage src={what_we_do_mid} alt="Kaju Katli Boxes" />
  <CardTitle>Kaju Katli Boxes</CardTitle>
  <HoverOverlay>
    <OverlayTitle>Speciality : KAJU KATLI BOXES</OverlayTitle>
    <OverlayText>
      At Saffron, we take pride in our signature Kaju Katli sweet boxes, crafted to perfection with the finest materials. Each piece is a symbol of luxury, purity, and taste, making it the perfect gift for any occasion. Packaged beautifully and made with love, these sweet treats are not just desserts—they're a celebration in every bite.
    </OverlayText>
    <OverlayButton onClick={(e) => {
      e.stopPropagation();
      scrollToSection('contact');
    }}>
      Contact us
    </OverlayButton>
  </HoverOverlay>
</LargeCard>
<SmallCard onClick={() => scrollToSection('contact')}>
            <CardImage src={what_we_do_right} alt="Event Planning" />
            <CardTitle>Event Planning</CardTitle>
            <HoverOverlay>
              <OverlayTitle>Outdoor Catering</OverlayTitle>
              <OverlayText>
                Experience the joy of dining al fresco with Saffron's outdoor catering services. Whether it's a wedding, garden party, or festival, we bring gourmet cuisine to your outdoor setting.
              </OverlayText>
              <OverlayButton onClick={(e) => {
                e.stopPropagation();
                scrollToSection('contact');
              }}>
                Contact us
              </OverlayButton>
            </HoverOverlay>
          </SmallCard>
</CardContainer>
      </WhatWeDoSection>
      <hr className='line_hr' style={{height: "2px", width: "80%", backgroundColor: "#C19D56", border: "none"}}/>
      <HappyMomentsSection id="occasions">
        <SectionTitle2>For Your all Happy Moments !</SectionTitle2>
        <img src={loader} alt="Loader" />
        <SectionText>
          At Saffron, we take pride in our signature Kaju Katli sweet boxes, crafted to perfection with the finest
          materials. Each piece is a symbol of luxury, purity, and taste, making it the perfect gift for any occasion.
        </SectionText>
        <HappyMomentsImage 
          src={foryorallhapymoments} 
          alt="Happy Moments Gift Boxes" 
        />
      </HappyMomentsSection>
     
      <CustomizationSection>
        <SectionTitle>Gifts</SectionTitle>
        <SectionSubtitle>CUSTOMIZATION</SectionSubtitle>
        <SectionText>Make you Own Boxes with our Customised Service</SectionText>
        <CustomizationCards>
          <CustomizationCard>
            <CustomizationImage src={customisation_card_1} alt="Design your Box" />
          </CustomizationCard>
          <CustomizationCard>
            <CustomizationImage src={customisation_card_2} alt="Add a personal Touch" />
          </CustomizationCard>
          <CustomizationCard>
            <CustomizationImage src={customisation_card_3} alt="Customize you sweets" />
          </CustomizationCard>
        </CustomizationCards>
      </CustomizationSection>

      <TestimonialsSection id="testimonials">
        <SectionTitle>Gifts</SectionTitle>
        <SectionSubtitle>WHAT OUR CLIENTS SAY</SectionSubtitle>
        <TestimonialContainer>
          <TestimonialCard>
            <QuoteIcon src={quote} alt="Quote" />
            <TestimonialText>
              We recently ordered Kaju Katli boxes from Saffron Caterers for our Diwali celebrations at the office. The
              quality and packaging were top-notch, and the sweets were absolutely delicious. The feedback from our team was
              overwhelmingly positive. Highly recommended!
            </TestimonialText>
            <TestimonialAuthor>Priya Kapoor, HR Manager</TestimonialAuthor>
          </TestimonialCard>
          <TestimonialCard>
            <QuoteIcon src={quote} alt="Quote" />
            <TestimonialText>
              Saffron Caterers provided exceptional catering for our annual corporate event. The Kaju Katli boxes were a huge
              hit among our employees and clients. The presentation was exquisite, and the taste was simply divine. We look
              forward to collaborating with them for our future events.
            </TestimonialText>
            <TestimonialAuthor>Rajesh Sharma, Chief Executive Officer</TestimonialAuthor>
          </TestimonialCard>
          <TestimonialCard>
            <QuoteIcon src={quote} alt="Quote" />
            <TestimonialText>
              Saffron Caterers catered our family reunion, and we were thrilled with their service. The Kaju Katli was the
              star of the show, and everyone loved it. Their team was professional, friendly, and ensured everything ran
              smoothly. Great job!
            </TestimonialText>
            <TestimonialAuthor>Vivek Joshi, Event Host</TestimonialAuthor>
          </TestimonialCard>
        </TestimonialContainer>
      </TestimonialsSection>

      <ContactSection id="contact">
        <KajuTopImage src={contact_kaju_2} alt="Kaju Top" />
        <ContactForm onSubmit={handleSubmit}>
          <SectionTitle3>GET IN TOUCH WITH US</SectionTitle3>
          <FormRow>
            <FormInput type="text" name="fullName" placeholder="Full Name" required maxLength="100" />
            <PhoneInput>
              <PhoneFlag src={phone_flag} alt="India flag" />
              <FormInput type="tel" name="phone" placeholder="Enter phone number" required pattern="[6-9]\d{9}" title="Please enter a valid 10-digit Indian mobile number" />
            </PhoneInput>
          </FormRow>
          <FormTextarea name="message" placeholder="Your message" required maxLength="500" />
          <FormButton type="submit">
            CONTACT US
          </FormButton>
        </ContactForm>
        <KajuBottomImage src={contact_kaju} alt="Kaju Bottom" />
      </ContactSection>
      <Footer>
        <FooterContent>
          <FooterColumn>
            <LogoContainer>
              <a href="https://saffroncaterers.com">
                <FooterLogo src={footerlogo} alt="Saffron Caterers Logo" />
              </a>
              <SinceText>SINCE 1980</SinceText>
            </LogoContainer>
          </FooterColumn>
          <FooterInfoColumn>
            <FooterInfo>
              <FooterInfoItem>
                <FooterIcon src={phone} alt="Phone" />
                <CTALink href="tel:+919828163623">+91 9828163623</CTALink>, <CTALink href="tel:+918079033292">8079033292</CTALink>
              </FooterInfoItem>
              <FooterInfoItem>
                <FooterIcon src={address} alt="Address" />
                <FooterText>Saffron Caterers Shop No. 31,32 Bagda Misthan Bhandar Dher Ka Balaji Sikar Road Jaipur, 302039</FooterText>
              </FooterInfoItem>
              <FooterInfoItem>
                <FooterIcon src={email} alt="Email" />
                <CTALink href="mailto:pinksaffron17@gmail.com">pinksaffron17@gmail.com</CTALink>
              </FooterInfoItem>
            </FooterInfo>
          </FooterInfoColumn>
    <FooterColumn>
      <FooterLinks>
        <FooterLink href="#story">About us</FooterLink>
        <FooterLink href="#what-we-do">Our Services</FooterLink>
        <FooterLink href="#what-we-do">Our Products</FooterLink>
      </FooterLinks>
    </FooterColumn>
    <FooterColumn>
      <FooterLinks>
        <FooterLink href="#testimonials">What our clients say</FooterLink>
        <FooterLink href="#occasions">Customized Gift Boxes</FooterLink>
      </FooterLinks>
    </FooterColumn>
    <FooterColumn2>
    <SectionTitle2>Connect with us</SectionTitle2>
    <SocialIcons>
      <SocialIcon src={youtube} alt="YouTube" whileHover={{ scale: 1.1 }} />
      <SocialIcon src={instagram} alt="Instagram" whileHover={{ scale: 1.1 }} />
      <SocialIcon src={facebook} alt="Facebook" whileHover={{ scale: 1.1 }} />
      <SocialIcon src={twitter} alt="Twitter" whileHover={{ scale: 1.1 }} />
    </SocialIcons>
  </FooterColumn2>
  </FooterContent>
  <Copyright>© 2024 The saffron caterers . All Rights Reserved Made with ❤️ by Jethi Tech Solutions</Copyright>
</Footer>

      {showScrollToTop && (
        <ScrollToTopButton
          onClick={scrollToTop}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          whileHover={{ scale: 1.1 }}
        >
          ↑
        </ScrollToTopButton>
      )}
    </Container>
  );
};

export default HomePage;