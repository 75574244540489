import React from 'react';
import { Link } from 'react-router-dom';
import notFoundImage from '../assets/404.png';

const NotFound = () => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      textAlign: 'center'
    }}>
      <img src={notFoundImage} alt="404 Not Found" style={{ maxWidth: '100%', height: 'auto' }} />
      <h1>Oops! Page Not Found</h1>
      <p>The page you are looking for doesn't exist or has been moved.</p>
      <Link to="/" style={{
        marginTop: '20px',
        padding: '10px 20px',
        backgroundColor: '#C19D56',
        color: 'white',
        textDecoration: 'none',
        borderRadius: '5px'
      }}>
        Go Back to Home
      </Link>
    </div>
  );
};

export default NotFound;